import type { QueryCountries, DisplayCountries } from "~/@types/countries"

export default (country?: QueryCountries): DisplayCountries => {
  if (!country) {
    return {} as DisplayCountries
  }

  return {
    ...country,
    permalink: `/countries/${country?.slug?.current}`,
  }
}
