import type { QueryIndustries, DisplayIndustries } from "~/@types/industries"

export default (industry?: QueryIndustries): DisplayIndustries => {
  if (!industry) {
    return {} as DisplayIndustries
  }

  return {
    ...industry,
    permalink: `/industries/${industry?.slug?.current}`,
  }
}
