import type { QueryCareers, DisplayCareers } from "~/@types/careers"

export default (career?: QueryCareers): DisplayCareers => {
  if (!career) {
    return {} as DisplayCareers
  }

  return {
    ...career,
    slug: career.slug?.current,
    permalink: `/careers/${career?.slug?.current}`,
  }
}
