import type {
  QueryOpportunities,
  DisplayOpportunities,
} from "~/@types/opportunities"

export default (post: QueryOpportunities): DisplayOpportunities => {
  const { brand, career, jobType, country, city, industry, ...opportunity } =
    reactive(toRefs(post))

  const { logo, ...displayBrand } = useBrand(brand)
  const { name: cityName, shortName: cityShortName } = useCity(city)
  const { name: countryName, code: countryCode } = useCountry(country)
  const { name: typeName } = useJobType(jobType)

  const permalink = `/opportunities/${post.slug?.current}`
  const location = cityShortName ?? cityName ?? countryName
  const descriptionParts = [typeName, location, countryCode].filter(Boolean)

  return {
    ...opportunity,
    logo,
    permalink,
    description: descriptionParts.join(", "),
    brand: displayBrand,
    career: useCareer(career),
    city: useCity(city),
    country: useCountry(country),
    jobType: useJobType(jobType),
    industry: useIndustry(industry),
  }
}
