import type { QueryCities, DisplayCities } from "~/@types/cities"

export default (city?: QueryCities): DisplayCities => {
  if (!city) {
    return {} as DisplayCities
  }

  return {
    ...city,
    permalink: `/cities/${city?.slug?.current}`,
  }
}
