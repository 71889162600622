import type { QueryJobType, DisplayJobType } from "~/@types/jobType"

export default (type?: QueryJobType): DisplayJobType => {
  if (!type) {
    return {} as DisplayJobType
  }

  return {
    ...type,
    permalink: `/types/${type?.slug?.current}`,
  }
}
